import { doc, collection, writeBatch } from 'firebase/firestore';
import { db } from '../firebase-config';
import { createConversation } from './create-conversations';
import { populatePatientSearchTokens } from '../components/patient-management/patient-list/patientSearchTokenQuery';

export default async function createPatientData(user, userEmail, formName, formInputs, index) {
  const batch = writeBatch(db);
  const date = Date.now();

  const userObj = {
    acceptedAgreements: {},
    identification: 'unverified',
    email: userEmail,
    marketingEmails: true,
    shipping: {},
    forms: {},
    catalogueItems: {},
    prescriptions: {},
    preferredPharmacy: {},
    doctor: '',
    reference: user,
  };

  const generalObj = {
    'basic info': {
      'first form completed': false,
      'first treatment assigned': false,
    },
    'general health': {},
    condition: {},
    treatment: {},
    surgery: {},
    'checkups required': [],
    forms: {},
  };

  try {
    if (formName) {
      generalObj.forms[formName] = {
        step: index + 1,
        started: date,
      };
      userObj.forms[formName] = 'in progress';
      userObj.catalogueItems[formName] = [];
    }

    batch.set(doc(db, 'patients', user), userObj);
    // POTENTIAL BUG: Some user's don't seem to have backups which causes verifyBeforeUpdateEmail to fail
    // as we attempt to update a specific doc that doesn't exist.
    batch.set(doc(db, 'backups', user), { email: userEmail });
    batch.set(doc(db, 'patients', user, 'general', 'information'), generalObj);
    if (typeof formInputs === 'object') batch.update(doc(db, 'patients', user, 'general', 'information'), formInputs);
    batch.set(doc(collection(db, 'patients', user, 'activity')), {
      createdAt: date,
      generalData: true,
      author: 'System',
      text: 'Patient account created.',
    });
    await batch.commit();
    await populatePatientSearchTokens(user);

    const nurseDetails = {
      id: 'nurse',
      email: 'nurse@candor.org',
      name: 'Practice Nurse',
      notifications: 0,
      role: 'nurse',
    };
    const supportDetails = {
      id: 'support',
      email: 'support@candor.org',
      name: 'Candor Support',
      notifications: 0,
      role: 'support',
    };
    const doctorDetails = {
      id: 'doctor',
      email: 'doctor@candor.org',
      name: 'Candor Medical Practitioner',
      notifications: 0,
      role: 'doctor',
    };
    const patientDetails = {
      id: user,
      email: userEmail,
      name: '',
      notifications: 1,
      role: 'patient',
    };

    createConversation(patientDetails, supportDetails);
    createConversation(patientDetails, nurseDetails);
    createConversation(patientDetails, doctorDetails);
  } catch (error) {
    console.log(error);
  }
}
