import { collection, doc, getDoc, getDocs, limit, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import { captureException as sentryCaputureException } from '@sentry/react';

import { db } from '../../../firebase-config';
import { tokenizeNames, tokenizeSearchInput } from './patientSearchTokenization';

const QUERY_RESULT_LIMIT = 30;

// Create firestore query to search for patients by the tokenized name
export const queryPatientsByName = (nameInput) => {
  const name = nameInput.trim();
  if (!name) {
    return [];
  }

  const tokenizedInput = tokenizeSearchInput(name);
  const whereQueries = tokenizedInput.map((tokenizedName) => where(`searchTokens.${tokenizedName}`, '==', true));
  return getDocs(query(collection(db, 'patients'), limit(QUERY_RESULT_LIMIT), ...whereQueries));
};

// Populate the searchTokens field in the patient document with the tokenized names
export const populatePatientSearchTokens = async (patientId) => {
  const patientDoc = await getDoc(doc(db, 'patients', patientId));
  const searchTokens = tokenizeNames(patientDoc.firstName, patientDoc.lastName).reduce(
    (acc, tokenizedName) => ({ ...acc, [tokenizedName]: true }),
    {},
  );
  try {
    await updateDoc(doc(db, 'patients', patientId), { searchTokens });
  } catch (error) {
    sentryCaputureException(error, {
      extra: {
        patientId,
        searchTokens,
        issueIn: 'populatePatientSearchTokens',
        message: 'Cannot update patient search tokens',
      },
    });
  }
};

// Populate the searchTokens field in the patients collection with the tokenized names
export const batchPopulatePatientsSearchTokens = async () => {
  const patients = await getDocs(query(collection(db, 'patients')));
  const batch = writeBatch(db);
  patients.forEach((patient) => {
    const searchTokens = tokenizeNames(patient.data().firstName, patient.data().lastName).reduce(
      (acc, tokenizedName) => ({ ...acc, [tokenizedName]: true }),
      {},
    );
    batch.update(patient.ref, { searchTokens });
  });
  await batch.commit();
};
